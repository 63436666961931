// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/components/useDialog.tsx"
);
import.meta.hot.lastModified = "1691634140328.434";
}
// REMIX HMR END

import type { ReactNode, SyntheticEvent } from 'react';
import { memo, useCallback, useRef } from 'react';

export function useDialog() {
  const dialogRef = useRef<HTMLDialogElement>(null);

  const open = useCallback(() => {
    dialogRef.current?.showModal();
  }, []);

  const close = useCallback((e?: SyntheticEvent, backdrop = false) => {
    if (backdrop && !(e?.target instanceof HTMLDialogElement)) {
      return;
    }
    dialogRef.current?.close();
  }, []);

  const backdropClose = useCallback(
    (e: SyntheticEvent) => {
      close(e, true);
    },
    [close],
  );

  return {
    open,
    close,
    Dialog: memo(function Dialog({
      children,
      className,
    }: {
      children: ReactNode;
      className?: string;
    }) {
      return (
        <dialog className={className} ref={dialogRef} onClick={backdropClose}>
          {children}
        </dialog>
      );
    }),
  };
}
